// import "./src/styles/animations.css"
// import "./src/styles/bootstrap.min.css"
// import "./src/styles/main.css"
require("./src/styles/animations.css")
require("./src/styles/bootstrap.min.css")
require("./src/styles/main.css")
require("./src/styles/custom.css")
// require("./src/styles/font-awesome.css")





// exports.onInitialClientRender = () => {
//   console.log("ReactDOM.render has executed",window)
//   setTimeout(()=> {
//     const script = document.createElement("script");
//
//      script.src = "/main.js";
//      script.async = false;
//      script.id='mainscript';
//      script.type = 'text/javascript';
//      document.body.appendChild(script);
//
//   }, 500)
//
// }
//
//
//
// //
// exports.onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname)
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
//   if(document.getElementById("mainscript")) {
//
//       document.getElementById("mainscript").remove();
//       const script = document.createElement("script");
//
//        script.src = "/main.js";
//        script.async = false;
//        script.id='mainscript';
//        script.type = 'text/javascript';
//        document.body.appendChild(script);
//
//   }
//
//
// }
// exports.onClientEntry = () => {
//   document.getElementById("mainscript").remove();
// }
