// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-el-404-js": () => import("./../../../src/pages/el/404.js" /* webpackChunkName: "component---src-pages-el-404-js" */),
  "component---src-pages-el-contact-js": () => import("./../../../src/pages/el/contact.js" /* webpackChunkName: "component---src-pages-el-contact-js" */),
  "component---src-pages-el-index-js": () => import("./../../../src/pages/el/index.js" /* webpackChunkName: "component---src-pages-el-index-js" */),
  "component---src-pages-el-works-js": () => import("./../../../src/pages/el/works.js" /* webpackChunkName: "component---src-pages-el-works-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-works-js": () => import("./../../../src/pages/en/works.js" /* webpackChunkName: "component---src-pages-en-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-mainabout-js": () => import("./../../../src/templates/mainabout.js" /* webpackChunkName: "component---src-templates-mainabout-js" */),
  "component---src-templates-mainservices-js": () => import("./../../../src/templates/mainservices.js" /* webpackChunkName: "component---src-templates-mainservices-js" */),
  "component---src-templates-seotemplate-js": () => import("./../../../src/templates/seotemplate.js" /* webpackChunkName: "component---src-templates-seotemplate-js" */),
  "component---src-templates-servicetemplate-js": () => import("./../../../src/templates/servicetemplate.js" /* webpackChunkName: "component---src-templates-servicetemplate-js" */),
  "component---src-templates-simplepage-js": () => import("./../../../src/templates/simplepage.js" /* webpackChunkName: "component---src-templates-simplepage-js" */),
  "component---src-templates-worktemplate-js": () => import("./../../../src/templates/worktemplate.js" /* webpackChunkName: "component---src-templates-worktemplate-js" */)
}

